.leaflet-fontawesome-markers {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    text-align: center;
    margin-left: -15px;
    margin-top: -50px;
    width: 30px;
    height: 50px;
}

.leaflet-fontawesome-markers .marker-icon-svg {
    position: absolute;
}

.leaflet-fontawesome-markers .feature-icon {
    position: absolute;
    font-size: 20px;
    line-height: 0px;
    left: 8px;
    top: 16px;
    display: inline-block;
}